import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueGtag from 'vue-gtag'

// import PrimeVue from 'primevue/config';

// // Import PrimeVue styles
// import 'primevue/resources/themes/saga-blue/theme.css'; // theme
// import 'primevue/resources/primevue.min.css'; // core CSS
// import 'primeicons/primeicons.css'; // icons

// Import BootstrapVue CSS
import './assets/scss/app.scss'

const GA4_PROPERTY_ID = process.env.VUE_APP_GA4_PROPERTY_ID

library.add(fas, far, fab)

createApp(App)
    .use(router)
    .use(VueGtag, {
        config: { id: GA4_PROPERTY_ID }
      })
    // .use(PrimeVue)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
