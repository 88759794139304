<template>
  <ul v-if="relations.length < 5" class="relations">
    <li v-for="(relation, index) in relations" :key="index">
      <span v-if="index > 0"
        ><font-awesome-icon :icon="['fas', 'chevron-right']" class="icon"
      /></span>
      <Tooltip :text="relation">
        <span class="node" v-tooltip="'tooltip'">{{ relation }}</span>
      </Tooltip>
    </li>
  </ul>
  <ul v-else class="relations">
    <li>
      <Tooltip :text="relations[0]">
        <span class="node">{{ relations[0] }}</span>
      </Tooltip>
    </li>
    <li class="ellipsis-content">
      <span><font-awesome-icon :icon="['fas', 'chevron-right']" class="icon" /></span>
      <font-awesome-icon :icon="['fas', 'ellipsis']" class="ellipsis" />
      <span class="ellipsis-num">{{ relations.length - 2 }}</span>
      <font-awesome-icon :icon="['fas', 'ellipsis']" class="ellipsis" />
    </li>
    <li>
      <span><font-awesome-icon :icon="['fas', 'chevron-right']" class="icon" /></span>
      <Tooltip :text="relations[relations.length - 1]">
        <span class="node">{{ relations[relations.length - 1] }}</span>
      </Tooltip>
    </li>
  </ul>
</template>

<script>
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: "Relation",
  components: {
    Tooltip,
  },
  props: {
    relations: Array,
  },
};
</script>

<style lang="scss" scoped>
.relations {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px 5px;
  margin: 0;  
  user-select: none;
  min-height: 52px;
  
  & > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      font-size: 10px;
      margin-right: 10px;
    }
    .node {
      display: block;
      font-size: 11px;
      padding: 2px 10px;
      background-color: #efefef;
      border-radius: 3px;
      font-weight: bold;
      max-width: 50px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      user-select: none;
    }

    .ellipsis {
      padding: 0px 3px;
      font-size: 11px;
    }

    .ellipsis-num {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #efefef;
      font-size: 12px;
    }

    &.ellipsis-content {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 576px) {
  .relations {
    min-height: 49px;
    & > li {
      .node {
        font-size: 10px;
        max-width: 40px;
      }
    }
  }
}
</style>
