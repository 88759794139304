<template>
  <header>
    <Container>
        <Relation v-if="relations.length" :relations="relations" />
      <div class="content" v-else>
        <router-link to="/">
          <div class="logo">
            <img src="../assets/images/logo.png" alt="" />
            <span>marumado</span>
          </div>
        </router-link>
      </div>
    </Container>
  </header>
</template>

<script>
import Container from "@/components/Container.vue";
import Relation from "@/components/Relation.vue";

export default {
  name: "Header",
  components: {
    Container,
    Relation,
  },

  props: {
    relations: Array,
  },
};
</script>
