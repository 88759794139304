import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URI, // Replace this with your API base URL
    timeout: 10000, // Timeout for requests
});

export const getHomeDatas = async () => {
    return instance.get('/');
};

export const getSearchDatas = async (params) => {
    return instance.get('/', { params });        
};

export const getADDatas = async (params) => {
    return instance.get('/', { params });        
};