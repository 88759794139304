<template>
  <div class="tooltip-container"
       @mouseenter="show"
       @mouseleave="hide"
       @touchstart="show"
       @touchend="hide">
    <slot></slot>
    <div v-if="showTooltip" class="tooltip">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    show() {
      this.showTooltip = true;
    },
    hide() {
      this.showTooltip = false;
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 5;
  display: none;
  font-size: 10px;
  width: 100%;
}

.tooltip-container:hover .tooltip {
  display: block;
}
</style>
