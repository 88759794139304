<template>
  <Header :relations="relations" />
  <section class="page-home p-padding">
    <Container>
      <ContentLoader v-if="isLoading" width="100%" height="300"></ContentLoader>
      <div v-else>
        <div class="content">
          <div
            class="v-item"
            v-for="primeryData in primeryDatas"
            :key="primeryData.media_id"
            :draggable="true"
            @dragstart="dragStart(primeryData)"
            @dragend="dragEnd"
            @click="handleClick(primeryData)"
            @contextmenu.prevent="handleRightClick(primeryData)"
            @touchmove.prevent="onTouchMove($event, primeryData)"
            @touchstart="onTouchStart($event, primeryData)"
            @touchend="onTouchEnd(primeryData)"
            :style="{
              backgroundColor: itemBgColor(primeryData.media_id) }"
          >
            <div class="item-content">
              <div v-if="primeryData.image" class="image-content">
                <img :src="primeryData.image" :alt="primeryData.title" />
              </div>
              <div class="info">
                <h2>{{ primeryData.title }}</h2>
                <div class="sub-title" v-if="primeryData.subtitle">
                  {{ primeryData.subtitle }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="drop-content"
            @click="dropHandleClick"
            @drop="dropElement"
            @dragover.prevent
            @dragenter.prevent
          >
            <div v-if="dropItem">
              <img
                v-if="dropItem.image"
                :src="dropItem.image"
                :alt="dropItem.title"
              />
              <div v-else class="info">
                <h2>{{ dropItem.title }}</h2>
                <div class="sub-title" v-if="dropItem.subtitle">
                  {{ dropItem.subtitle }}
                </div>
              </div>
            </div>
            <div v-else>Drop here</div>
          </div>
        </div>
        <div class="wrap-content">
          <div
            class="wrap-item"
            v-for="seconderyData in seconderyDatas"
            :key="seconderyData.media_id"
            :draggable="true"
            @dragstart="dragStart(seconderyData)"
            @dragend="dragEnd"
            @click="handleClick(seconderyData)"
            @contextmenu.prevent="handleRightClick(seconderyData)"
            @touchmove.prevent="onTouchMove($event, seconderyData)"
            @touchstart="onTouchStart($event, seconderyData)"
            @touchend="onTouchEnd(seconderyData)"
            :style="{
              backgroundColor: itemBgColor(seconderyData.media_id)}"
          >
            <img
              v-if="seconderyData.image"
              :src="seconderyData.image"
              :alt="seconderyData.title"
            />
            <div v-else class="info">
              <h2>{{ seconderyData.title }}</h2>
              <div class="sub-title">{{ seconderyData.subtitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </section>

  <Modal v-if="showAD1" @close="showAD1 = false" :show="showAD1">
    <div class="adv-content">
      <div class="adv-title" v-if="ad1Data.title">{{ ad1Data.title }}</div>
      <div class="img-content">
        <img
          :src="ad1Data.image"
          :alt="ad1Data.title"
          @click="modalImgClick(ad1Data)"
        />
      </div>
    </div>
    <button class="modal-close" @click="showAD1 = false">
      <font-awesome-icon :icon="['fas', 'close']" />
    </button>
  </Modal>

  <Modal v-if="showAD2" @close="showAD2 = false" :show="showAD2">
    <div class="adv-content">
      <div class="adv-title" v-if="ad2Data.title">{{ ad2Data.title }}</div>
      <div class="img-content">
        <img
          :src="ad2Data.image"
          :alt="ad2Data.title"
          @click="modalImgClick(ad2Data)"
        />
      </div>
    </div>
    <button class="modal-close" @click="showAD2 = false">
      <font-awesome-icon :icon="['fas', 'close']" />
    </button>
  </Modal>

  <footer>
    <Container>
      <div class="content">
        <div class="menu" to="/" @click="handleHomeClick('/')" ref="homeLink">
          <font-awesome-icon :icon="['fas', 'house']" class="icon" />
        </div>
        <div class="menu" @click="handleHomeReload()">
          <font-awesome-icon :icon="['fas', 'arrows-rotate']" class="icon" />
        </div>
        <div
          class="menu"
          @click="handleBack()"
          :style="{ color: historys.length > 1 ? 'black' : '#888' }"
        >
          <font-awesome-icon :icon="['fas', 'rotate-left']" class="icon" />
        </div>
      </div>
    </Container>
  </footer>
</template>

<script>
import Container from "@/components/Container.vue";
import Relation from "@/components/Relation.vue";
import Header from "@/components/Header.vue";
import { getHomeDatas, getSearchDatas, getADDatas } from "../config/api";
import { ContentLoader } from "vue-content-loader";
import Modal from "@/components/Modal.vue";

const ADThreshold = process.env.VUE_APP_AD_THRESHOLD;
const searchLimit = process.env.VUE_APP_SEARCH_LIMIT;

export default {
  name: "HomeView",
  components: {
    Container,
    ContentLoader,
    Relation,
    Header,
    Modal,
  },
  data() {
    return {
      isLoading: false,
      apiDatas: [],
      primeryDatas: [],
      seconderyDatas: [],
      draggedItem: null,
      dropItem: null,
      touchStartX: 0,
      touchStartY: 0,
      touchStartTime: 0,
      longPressTimer: null,
      clickStartTime: 0,
      deltaX: 0,
      deltaY: 0,
      relations: [],
      historys: [],
      isPressHandleBack: false,
      isPressHandReload: false,
      countUpdate: 0,
      countSearch: 0,
      showAD1: false,
      showAD2: false,
      ad1Data: null,
      ad2Data: null,
      depth: 0,
      rightClickedId: null,
    };
  },
  computed: {
    
  },
  mounted() {
    this.isLoading = true;
    this.fetchHomeDatas();
  },
  methods: {
    async fetchHomeDatas() {
      try {
        const response = await getHomeDatas();
        this.apiDatas = response.data.data;
        this.primeryDatas = response.data.data.slice(0, 4);
        this.seconderyDatas = response.data.data.slice(4, 7);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching primeryDatas:", error);
      }
    },
    async fetchSearchDatas(params) {
      try {
        const response = await getSearchDatas(params);
        this.apiDatas = response.data.data;
        this.primeryDatas = response.data.data.slice(0, 4);
        this.seconderyDatas = response.data.data.slice(4, 7);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching primeryDatas:", error);
      }
    },

    async fetchADDatas(params) {
      try {
        const response = await getADDatas(params);
        return response.data.data[0] ?? [];
      } catch (error) {
        console.error("Error fetching primeryDatas:", error);
      }
    },

    itemBgColor(media_id) {
      if(this.rightClickedId == media_id && this.relations.length > 0) {
        return '#efefef';
      }
      return 'transparent';
    },
    
    dragStart(draggedItem) {
      this.isPressHandleBack = false;
      this.isPressHandReload = false;
      this.draggedItem = draggedItem;
    },
    dragEnd() {
      this.draggedItem = null;
    },
    dropElement(event) {
      event.preventDefault();
      if (this.draggedItem) {
        if (this.depth < searchLimit) {
          this.dropItem = this.draggedItem;
        }
        this.handleShowAD2();
      }
    },

    handleClick(data) {
      this.$gtag.event("button_click", {
        event_category: "Engagement",
        event_label: data.title,
      });

      if (data.url) {
        window.open(data.url, "_blank");
      }
    },

    handleRightClick(data) {
      console.log("data.media_id:", data.media_id);
      this.rightClickedId = data.media_id;
      this.relations = data.relation;
    },

    onTouchStart(event, touchData) {
      this.$gtag.event("button_click", {
        event_category: "Engagement",
        event_label: touchData.title,
      });

      this.isPressHandleBack = false;
      this.isPressHandReload = false;

      this.touchStartX = event.touches[0].clientX;
      this.touchStartY = event.touches[0].clientY;
      this.touchStartTime = Date.now();
      this.touchData = touchData;

      this.deltaX = 0;
      this.deltaY = 0;
      // Start timer for long press detection
      // this.longPressTimer = setTimeout(() => this.checkLongPress(touchData), 1000); // Adjust the duration as needed

      this.startTouchDragging(event, touchData);
    },

    onTouchMove(event, touchData) {
      const touchX = event.touches[0].clientX;
      const touchY = event.touches[0].clientY;

      // Calculate movement distance
      this.deltaX = Math.abs(touchX - this.touchStartX);
      this.deltaY = Math.abs(touchY - this.touchStartY);
    },
    onTouchEnd(touchData) {
      const touchEndTime = Date.now();
      const touchDuration = touchEndTime - this.touchStartTime;

      if (touchDuration < 500) {
        if (this.deltaX < 10 && this.deltaY < 10) {
          if (touchData.url) {
            window.open(touchData.url, "_blank");
          }
        }
      } else {
        if (this.deltaX < 10 && this.deltaY < 10) {
          this.rightClickedId = touchData.media_id;
          this.relations = touchData.relation;
        }
      }
      clearTimeout(this.longPressTimer);
    },

    startTouchDragging(event, touchDragData) {
      // Prevent default behavior to avoid scrolling while dragging
      event.preventDefault();

      // Store the initial touch position
      const initialX = event.touches[0].clientX;
      const initialY = event.touches[0].clientY;

      // Store the data being dragged
      this.draggedData = touchDragData;

      // Clone the dragged element
      let targetElement = event.target;
      while (
        targetElement &&
        !targetElement.classList.contains("item-content") &&
        !targetElement.classList.contains("wrap-item")
      ) {
        targetElement =
          this.findParentWithClass(event.target, "item-content") ||
          this.findParentWithClass(event.target, "wrap-item");
      }

      if (!targetElement) {
        return null;
      }

      const clone = targetElement.cloneNode(true);

      // Get the width and height of the parent item
      const parentWidth = targetElement.offsetWidth * 0.9;
      const parentHeight = targetElement.offsetHeight * 0.9;

      // Add a class to the clone for styling

      clone.classList.add("dragged-item");

      if (parentHeight > parentWidth) {
        clone.classList.add("v-h");
      }

      // Append the clone to the body and position it according to the touch position
      document.body.appendChild(clone);
      clone.style.position = "absolute";
      // Set the width and height of the clone to match the parent item
      clone.style.width = `${parentWidth}px`;
      clone.style.height = `${parentHeight}px`;

      clone.style.left = `${initialX}px`;
      clone.style.top = `${initialY}px`;

      // Store initial touch position
      let prevX = initialX;
      let prevY = initialY;

      const moveHandler = (moveEvent) => {
        // Calculate distance moved
        const deltaX = moveEvent.touches[0].clientX - prevX;
        const deltaY = moveEvent.touches[0].clientY - prevY;

        // Update position of clone
        clone.style.left = `${parseFloat(clone.style.left) + deltaX}px`;
        clone.style.top = `${parseFloat(clone.style.top) + deltaY}px`;

        // Update previous touch position
        prevX = moveEvent.touches[0].clientX;
        prevY = moveEvent.touches[0].clientY;

        // Prevent default behavior to avoid scrolling while dragging
        moveEvent.preventDefault();
      };

      const endHandler = () => {
        // Cleanup event listeners
        document.removeEventListener("touchmove", moveHandler);
        document.removeEventListener("touchend", endHandler);

        // Remove the clone from the DOM
        if (this.checkOverlap(clone, "drop-content")) {
          this.checkTouchDropItem(touchDragData);
        }
        document.body.removeChild(clone);
      };

      // Add event listeners for touch movement and end of touch
      document.addEventListener("touchmove", moveHandler);
      document.addEventListener("touchend", endHandler);

      // Store the clone in a data property for later cleanup
      this.draggedClone = clone;
    },

    checkTouchDropItem(touchDragData) {
      if (this.deltaX >= 10 || this.deltaY >= 10) {
        if (this.depth < searchLimit) {
          this.dropItem = touchDragData;
        }

        this.handleShowAD2();
      }
    },

    findParentWithClass(element, className) {
      let parent = element.parentElement;
      while (parent) {
        if (parent.classList.contains(className)) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null; // If no parent with the specified class is found
    },
    checkOverlap(cloneElement, dropContentClassName) {
      const touchEndTime = Date.now();
      const touchDuration = touchEndTime - this.touchStartTime;

      if (touchDuration > 400) {
        // Get the bounding rectangle of the clone element
        const cloneRect = cloneElement.getBoundingClientRect();

        // Get all elements with the specified class name
        const dropContentElements =
          document.getElementsByClassName(dropContentClassName);

        // Iterate through all elements with the class name
        for (let i = 0; i < dropContentElements.length; i++) {
          const dropContentElement = dropContentElements[i];
          // Get the bounding rectangle of the drop content element
          const dropContentRect = dropContentElement.getBoundingClientRect();

          // Check for overlap
          if (
            !(
              cloneRect.right < dropContentRect.left ||
              cloneRect.left > dropContentRect.right ||
              cloneRect.bottom < dropContentRect.top ||
              cloneRect.top > dropContentRect.bottom
            )
          ) {
            // Overlap detected
            return true;
          }
        }
      }
      // No overlap detected
      return false;
    },
    handleHomeClick(to) {
      if (this.$route.path === to) {
        window.location.reload();
      } else {
        this.$router.push(to);
      }
    },
    handleHomeReload() {
      this.isPressHandReload = true;
      this.isLoading = true;
      this.countUpdate++; //increase update count
      this.relations = [];

      if (this.dropItem) {
        const params = {
          media_id: this.dropItem.media_id,
        };
        this.fetchSearchDatas(params);
      } else {
        this.fetchHomeDatas();
      }
    },
    handleBack() {
      let historys = this.historys;
      this.isPressHandleBack = true;
      this.relations = [];

      const wrapItems = document.querySelectorAll(".wrap-item");
      wrapItems.forEach((item) => {
        console.log('hanle back:', item)
        item.style.backgroundColor = "transparent";
      });

      const vItems = document.querySelectorAll(".v-item");
      vItems.forEach((item) => {
        item.style.backgroundColor = "transparent";
      });

      if (historys.length > 1) {
        historys.pop();
        this.primeryDatas = historys[historys.length - 1]?.apiDatas.slice(0, 4);
        this.seconderyDatas = historys[historys.length - 1]?.apiDatas.slice(
          4,
          7
        );
        this.dropItem = historys[historys.length - 1]?.dropItem;

        if (historys[historys.length - 1].isReload == false) {
          this.depth--;
        }

        this.historys = historys;
      }
    },
    dropHandleClick() {
      this.handleBack();
    },

    async handleShowAD1(countSearch, countUpdate) {
      let total = countSearch + countUpdate;

      if (total > 0 && total % ADThreshold == 0) {
        const params = {
          adv: "a",
        };
        this.ad1Data = await this.fetchADDatas(params);

        this.showAD1 = true;
      }
    },

    async handleShowAD2() {
      if (this.depth >= searchLimit) {
        const params = {
          adv: "b",
        };
        this.ad2Data = await this.fetchADDatas(params);
        this.showAD2 = true;
      }
    },

    modalImgClick(modalData) {
      const redirectUri = modalData.url;
      if (redirectUri) {
        window.open(redirectUri);
      }
    },
  },
  watch: {
    dropItem(newObj, oldValue) {
      if (!this.isPressHandleBack) {
        this.countSearch++;
      }

      if (!this.isPressHandReload && !this.isPressHandleBack) {
        this.depth++;
      }

      if (this.isPressHandleBack == false) {
        const params = {
          media_id: newObj.media_id,
        };
        this.isLoading = true;
        this.relations = [];
        this.fetchSearchDatas(params);
      }

      this.isPressHandReload = false;
      this.isPressHandleBack = false;
    },

    apiDatas(newobj, old) {
      if (!this.isPressHandleBack) {
        this.historys.push({
          apiDatas: newobj,
          dropItem: this.dropItem,
          isReload: this.isPressHandReload,
        });
      }
    },
    countSearch(newCountSearch, old) {
      this.handleShowAD1(newCountSearch, this.countUpdate);
    },

    countUpdate(newCountUpdate, old) {
      this.handleShowAD1(this.countSearch, newCountUpdate);
    },
  },
};
</script>
