<template>
  <div v-if="show" class="modal-backdrop" @click="close">
    <div class="modal" @click.stop :style="computedStyle">
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { getContainerWidth } from "../config/calcContainerWidth";

export default {
  name: "Modal",
  props: {
    show: {
      type: Boolean,
      required: true,      
    },
  },
   data() {
    return {
      maxWidth: 550,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.maxWidth = getContainerWidth();
  },
  computed: {
    computedStyle() {
      return {
        maxWidth: `${this.maxWidth}px !important`,
      };
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.5s;
}
.modal {
  position: relative;  
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  animation: slide-down 0.5s;
}
.modal-content {
  margin-top: 20px;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slide-down {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}
</style>
