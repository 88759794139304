export const getContainerWidth = () => {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    let header = 95
    let padding = 20
    let footer = 68
    let containerWidth = 0;

    if (windowWidth < 576) {
        header = 80
        padding = 20
        footer = 68
    } 
    
    containerWidth = (windowHeight - header - footer - padding + 40) / 1.333;


    if (containerWidth > windowWidth) {
        containerWidth = windowWidth;
    }

    return containerWidth;
}